<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    @click:outside="onClose"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        color="warning"
        block
        @click="onShowDialog"
        v-on="{ ...dialog }"
        data-test="activateButton"
      >
        Upload New Design File
      </v-btn>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title data-test="designToolbar"
            >Upload Revised <span class="text-capitalize">{{ designFilename ? designFilename.split("_").join(" ") : "" }}</span> </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="closeButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" md="8">
              <v-form ref="form">
                <v-file-input
                  show-size
                  counter
                  label="File"
                  v-model="designFile"
                  accept=".zip"
                  hint="File types: .ZIP"
                  persistent-hint
                  data-test="designFile"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-form>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                block
                :disabled="!designFile"
                color="secondary"
                @click="saveHandler"
                data-test="saveButton"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DesignFileUploadModal",
  props: {
    designFilename: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      designFile: null,
    };
  },
  methods: {
    onShowDialog() {
      this.designFile = null;
    },
    saveHandler() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("saveDesignFile", this.designFile);
      this.onClose();
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
